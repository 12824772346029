// src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, CircularProgress, Alert } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/api';
import { useAuth } from '../contexts/AuthContext';

function Dashboard() {
  const { token } = useAuth();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const response = await axios.get(API_ENDPOINTS.ANALYTICS, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAnalytics(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        if (error.response && error.response.status === 404) {
          setError('Analytics feature is not available. Please contact the administrator.');
        } else {
          setError('Failed to fetch analytics. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchAnalytics();
  }, [token]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!analytics) return <Typography>No data available</Typography>;


  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Today's Orders</Typography>
            <Typography variant="h4">{analytics.todayOrders || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Pending Orders</Typography>
            <Typography variant="h4">{analytics.pendingOrders || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Completed Orders (Last Week)</Typography>
            <Typography variant="h4">{analytics.completedOrdersLastWeek || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Total Revenue (Last Week)</Typography>
            <Typography variant="h4">${analytics.totalRevenueLastWeek || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Orders by Day (Last Week)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={analytics.ordersByDay || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" name="Orders" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: '1rem' }}>
            <Typography variant="h6">Top 5 Menu Items (Last Week)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={analytics.topMenuItems || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#82ca9d" name="Orders" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;