import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Button, TextField, Box, CircularProgress, Snackbar, TablePagination, IconButton, Chip
} from '@mui/material';
import { Alert } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import axios from 'axios';
import { API_ENDPOINTS, WS_ENDPOINTS, createWebSocket } from '../config/api';
import { debounce } from 'lodash';
import { useAuth } from '../contexts/AuthContext';

function OrderManagement() {
  const { user, token } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);
  const [notification, setNotification] = useState(null);
  const notificationSocketRef = useRef(null);
  const [filters, setFilters] = useState({
    status: '',
    created_after: '',
    created_before: '',
    min_price: '',
    max_price: '',
  });
  const [wsConnected, setWsConnected] = useState(false);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(API_ENDPOINTS.CAFETERIA_ORDERS, {
        headers: { Authorization: `Bearer ${token}` },
        params: { ...filters, page: page + 1, page_size: rowsPerPage },
      });
      console.log('Full API response:', response);
      console.log('Response data:', response.data);
      console.log('Response status:', response.status);
      
      const fetchedOrders = response.data.results || response.data || [];
      const totalCount = response.data.count || fetchedOrders.length;
      
      setOrders(fetchedOrders);
      setTotalOrders(totalCount);
      
      console.log('Updated state:', { orders: fetchedOrders, totalOrders: totalCount });
    } catch (error) {
      console.error('Error fetching orders:', error);
      console.error('Error response:', error.response);
      setError('Failed to fetch orders. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters, page, rowsPerPage, token]);

  useEffect(() => {
    console.log('Orders state updated:', orders);
  }, [orders]);

  const setupWebSocket = useCallback(() => {
    if (!token || !user || !user.cafeteria) {
      console.error('No token or cafeteria ID found. Cannot establish WebSocket connection.');
      return;
    }
  
    console.log('Attempting to connect to WebSocket...');
    const socket = createWebSocket(WS_ENDPOINTS.CAFETERIA_ORDERS(user.cafeteria.id), token);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('WebSocket connection established');
      setWsConnected(true);
      setError(null);
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);
      if (data.type === 'new_order' || data.type === 'order_update') {
        console.log('Order update received, fetching updated orders...');
        fetchOrders();
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setError('WebSocket connection error. Real-time updates may not work. Error: ' + (error.message || 'Unknown error'));
      setWsConnected(false);
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed', event.code, event.reason);
      setWsConnected(false);
      reconnectTimeoutRef.current = setTimeout(() => {
        console.log('Attempting to reconnect...');
        setupWebSocket();
      }, 5000);
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [fetchOrders, token, user]);

  const setupNotificationWebSocket = useCallback(() => {
    if (!token) {
      console.error('No token found. Cannot establish notification WebSocket connection.');
      return;
    }

    console.log('Attempting to connect to notification WebSocket...');
    const socket = createWebSocket(WS_ENDPOINTS.NOTIFICATIONS, token);
    notificationSocketRef.current = socket;

    socket.onopen = () => {
      console.log('Notification WebSocket connection established');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Notification received:', data);
      if (data.type === 'notification') {
        setNotification(data.message);
      }
    };

    socket.onerror = (error) => {
      console.error('Notification WebSocket error:', error);
    };

    socket.onclose = (event) => {
      console.log('Notification WebSocket connection closed', event.code, event.reason);
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [token]);

  useEffect(() => {
    fetchOrders();
    const cleanup = setupWebSocket();
    const notificationCleanup = setupNotificationWebSocket();
    return () => {
      if (cleanup) cleanup();
      if (notificationCleanup) notificationCleanup();
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        console.log('Closing WebSocket connection...');
        socketRef.current.close();
      }
      if (notificationSocketRef.current && notificationSocketRef.current.readyState === WebSocket.OPEN) {
        console.log('Closing notification WebSocket connection...');
        notificationSocketRef.current.close();
      }
    };
  }, [fetchOrders, setupWebSocket, setupNotificationWebSocket]);

  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      await axios.post(`${API_ENDPOINTS.CAFETERIA_ORDERS}${orderId}/update_status/`, 
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchOrders();
    } catch (error) {
      console.error('Error updating order status:', error);
      setError('Failed to update order status. Please try again.');
    }
  };

  const handleFilterChange = debounce((event) => {
    setFilters(prevFilters => ({ ...prevFilters, [event.target.name]: event.target.value }));
    setPage(0);
  }, 300);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending': return 'warning';
      case 'preparing': return 'info';
      case 'ready': return 'success';
      case 'delivered': return 'default';
      case 'cancelled': return 'error';
      default: return 'default';
    }
  };

  console.log('Render state:', { orders, loading, error, page, rowsPerPage, totalOrders });

  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Order Management</Typography>
      {wsConnected ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          Real-time updates are active.
        </Alert>
      ) : (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Real-time updates are currently unavailable. Attempting to reconnect...
        </Alert>
      )}
      <Paper sx={{ padding: '1rem', marginBottom: '1rem' }}>
        <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', alignItems: 'center' }}>
          <TextField label="Status" name="status" value={filters.status} onChange={handleFilterChange} size="small" />
          <TextField label="Created After" name="created_after" type="date" value={filters.created_after} onChange={handleFilterChange} InputLabelProps={{ shrink: true }} size="small" />
          <TextField label="Created Before" name="created_before" type="date" value={filters.created_before} onChange={handleFilterChange} InputLabelProps={{ shrink: true }} size="small" />
          <TextField label="Min Price" name="min_price" type="number" value={filters.min_price} onChange={handleFilterChange} size="small" />
          <TextField label="Max Price" name="max_price" type="number" value={filters.max_price} onChange={handleFilterChange} size="small" />
          <IconButton onClick={fetchOrders} color="primary" aria-label="refresh">
            <RefreshIcon />
          </IconButton>
        </Box>
      </Paper>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>
      )}

      {!loading && !error && (
        <>
          {Array.isArray(orders) && orders.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Items</TableCell>
                    <TableCell>Total Price</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{order.user ? order.user.username : 'N/A'}</TableCell>
                      <TableCell>
                        {order.items?.map(item => `${item.menu_item?.description || 'Unknown'} (x${item.quantity})`).join(', ') || 'No items'}
                      </TableCell>
                      <TableCell>${parseFloat(order.total_price || 0).toFixed(2)}</TableCell>
                      <TableCell>
                        <Chip label={order.status || 'Unknown'} color={getStatusColor(order.status)} />
                      </TableCell>
                      <TableCell>{order.created_at ? new Date(order.created_at).toLocaleString() : 'N/A'}</TableCell>
                      <TableCell>
                        {order.status === 'pending' && (
                          <Button onClick={() => handleStatusUpdate(order.id, 'preparing')} color="primary" size="small">
                            Start Preparing
                          </Button>
                        )}
                        {order.status === 'preparing' && (
                          <Button onClick={() => handleStatusUpdate(order.id, 'ready')} color="primary" size="small">
                            Mark as Ready
                          </Button>
                        )}
                        {order.status === 'ready' && (
                          <Button onClick={() => handleStatusUpdate(order.id, 'delivered')} color="primary" size="small">
                            Mark as Delivered
                          </Button>
                        )}
                        {(order.status === 'pending' || order.status === 'preparing') && (
                          <Button onClick={() => handleStatusUpdate(order.id, 'cancelled')} color="error" size="small">
                            Cancel
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No orders found.</Typography>
          )}
          <TablePagination
            component="div"
            count={totalOrders}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setNotification(null)} severity="info" sx={{ width: '100%' }}>
          {notification && notification.title}: {notification && notification.body}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default OrderManagement;