import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Grid, Card, CardContent, CardMedia, CardActions,
  Button, TextField, Switch, FormControlLabel, CircularProgress, Dialog,
  DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/api';
import { useAuth } from '../contexts/AuthContext';

function Menu() {
  const { token } = useAuth();
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchMenuItems = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(API_ENDPOINTS.MENUS, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMenuItems(response.data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
      setError('Failed to fetch menu items. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchMenuItems();
  }, [fetchMenuItems]);

  const handleEditClick = (item) => {
    setEditItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditItem(null);
  };

  const handleSaveChanges = async () => {
    try {
      await axios.patch(`${API_ENDPOINTS.MENUS}${editItem.id}/`, {
        price: editItem.price,
        is_available: editItem.is_available
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchMenuItems();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating menu item:', error);
      setError('Failed to update menu item. Please try again.');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Menu Items</Typography>
      <Grid container spacing={3}>
        {menuItems.map((item) => (
          <Grid item key={item.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={item.image || 'https://via.placeholder.com/140'}
                alt={item.description}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${item.price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Available: {item.is_available ? 'Yes' : 'No'}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleEditClick(item)}>Edit</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Edit Menu Item
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {editItem && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Price"
                type="number"
                value={editItem.price}
                onChange={(e) => setEditItem({...editItem, price: e.target.value})}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editItem.is_available}
                    onChange={(e) => setEditItem({...editItem, is_available: e.target.checked})}
                  />
                }
                label="Available"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveChanges}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Menu;