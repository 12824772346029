import React, { useMemo } from 'react';
import { Button, Container, Typography, Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { Restaurant, Assessment, ShoppingCart } from '@mui/icons-material';

const BackgroundContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
  color: theme.palette.text.primary,
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '150px',
  fontWeight: 'bold',
  margin: theme.spacing(1),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    transform: 'scale(1.05)',
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = useMemo(() => [
    { icon: <Restaurant fontSize="large" aria-hidden="true" />, title: 'Menu Management', description: 'Easily update and organize your cafeteria menu' },
    { icon: <Assessment fontSize="large" aria-hidden="true" />, title: 'Order Analytics', description: 'Get insights on popular items and peak hours' },
    { icon: <ShoppingCart fontSize="large" aria-hidden="true" />, title: 'Streamlined Orders', description: 'Efficiently process and manage customer orders' },
  ], []);

  return (
    <BackgroundContainer maxWidth="lg">
      <Box mb={6} textAlign="center">
        <Typography 
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          gutterBottom
          sx={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}
        >
          Welcome to Cafeteria Management
        </Typography>
        
        <Typography 
          variant={isMobile ? "body1" : "h6"}
          component="h2"
          gutterBottom
          sx={{ mb: 3, color: '#f1f1f1', textAlign: 'center' }}
        >
          Seamlessly manage your cafeteria orders and services
        </Typography>
        
        <Box mt={4}>
          <StyledButton
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/login"
          >
            Login
          </StyledButton>
          <StyledButton
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to="/register"
            sx={{ borderColor: '#fff', color: '#fff' }}
          >
            Register
          </StyledButton>
        </Box>
      </Box>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <FeatureCard elevation={3}>
              {feature.icon}
              <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
                {feature.title}
              </Typography>
              <Typography variant="body2">{feature.description}</Typography>
            </FeatureCard>
          </Grid>
        ))}
      </Grid>

      <Typography 
        variant="body2" 
        sx={{ mt: 6, color: '#e0e0e0', textAlign: 'center' }}
      >
        New to our system? Easily create an account and start managing your cafeteria today.
      </Typography>
    </BackgroundContainer>
  );
}

export default LandingPage;