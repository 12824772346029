import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';  
import { API_ENDPOINTS } from '../config/api';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cafeteriaId, setCafeteriaId] = useState('');
  const [cafeterias, setCafeterias] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCafeterias = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.CAFETERIAS);
        setCafeterias(response.data);
      } catch (error) {
        console.error('Failed to fetch cafeterias:', error);
      }
    };
    fetchCafeterias();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(API_ENDPOINTS.REGISTER, { username, password, cafeteria_id: cafeteriaId });
      navigate('/login');
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="cafeteria-select-label">Cafeteria</InputLabel>
          <Select
            labelId="cafeteria-select-label"
            value={cafeteriaId}
            onChange={(e) => setCafeteriaId(e.target.value)}
          >
            {cafeterias.map((cafeteria) => (
              <MenuItem key={cafeteria.id} value={cafeteria.id}>
                {cafeteria.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
        >
          Register
        </Button>
      </form>
      <Typography align="center" sx={{ mt: 2 }}>
        Already have an account?{' '}
        <Button component={Link} to="/login" variant="text" sx={{ fontWeight: 'bold' }}>
          Login here
        </Button>
      </Typography>
    </Container>
  );
}

export default Register;
