const API_BASE_URL = 'https://api.ecodish365.com';
const WS_BASE_URL = 'wss://api.ecodish365.com';

export const API_ENDPOINTS = {
  REGISTER: `${API_BASE_URL}/cafeteria/register/`,
  LOGIN: `${API_BASE_URL}/token/`,
  REFRESH_TOKEN: `${API_BASE_URL}/token/refresh/`,
  USER_PROFILE: `${API_BASE_URL}/profile/`,
  CAFETERIA_ORDERS: `${API_BASE_URL}/orders/`,
  MENUS: `${API_BASE_URL}/menuitems/`,
  CAFETERIAS: `${API_BASE_URL}/cafeterias/`,
  ANALYTICS: `${API_BASE_URL}/analytics/`,
  SEND_NOTIFICATION: `${API_BASE_URL}/send-notification/`,
  CAFETERIA_SETTINGS: `${API_BASE_URL}/cafeteria/settings/`,
};

export const WS_ENDPOINTS = {
  CAFETERIA_ORDERS: (cafeteriaId) => `${WS_BASE_URL}/cafeteria/${cafeteriaId}/orders/`,
  NOTIFICATIONS: `${WS_BASE_URL}/notifications/`,
};

export const createWebSocket = (endpoint, token) => {
  return new WebSocket(`${endpoint}?token=${token}`);
};