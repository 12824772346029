import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, CircularProgress, Avatar } from '@mui/material';
import { Alert } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/api';
import { useAuth } from '../contexts/AuthContext';

function CafeteriaProfile() {
  const { user, token, updateUser } = useAuth();
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    cafeteria: { name: '', location: '' }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(API_ENDPOINTS.USER_PROFILE, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch profile. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleCafeteriaChange = (event) => {
    const { name, value } = event.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      cafeteria: {
        ...prevProfile.cafeteria,
        [name]: value,
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await axios.put(API_ENDPOINTS.USER_PROFILE, profile, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Profile updated successfully');
      updateUser(response.data);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Avatar
          sx={{ width: 100, height: 100, mb: 2 }}
          src={user.avatar_url}
          alt={user.username}
        >
          {user.username ? user.username[0].toUpperCase() : ''}
        </Avatar>
        <Typography variant="h4" gutterBottom>Cafeteria Profile</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          name="username"
          value={profile.username}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={profile.email}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Cafeteria Name"
          name="name"
          value={profile.cafeteria.name}
          onChange={handleCafeteriaChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Cafeteria Location"
          name="location"
          value={profile.cafeteria.location}
          onChange={handleCafeteriaChange}
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Update Profile
          </Button>
        </Box>
      </form>
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
    </Container>
  );
}

export default CafeteriaProfile;