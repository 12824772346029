import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, Box, CircularProgress, Switch, FormControlLabel
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/api';
import { useAuth } from '../contexts/AuthContext';

function CafeteriaSettings() {
  const { token } = useAuth();
  const [settings, setSettings] = useState({
    name: '',
    location: '',
    opening_time: '',
    closing_time: '',
    is_open: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${API_ENDPOINTS.CAFETERIA_SETTINGS}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError('Failed to fetch settings. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [token]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: name === 'is_open' ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      await axios.put(`${API_ENDPOINTS.CAFETERIA_SETTINGS}`, settings, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Settings updated successfully');
    } catch (error) {
      console.error('Error updating settings:', error);
      setError('Failed to update settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>Cafeteria Settings</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Cafeteria Name"
          name="name"
          value={settings.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Location"
          name="location"
          value={settings.location}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Opening Time"
          name="opening_time"
          type="time"
          value={settings.opening_time}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Closing Time"
          name="closing_time"
          type="time"
          value={settings.closing_time}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={settings.is_open}
              onChange={handleChange}
              name="is_open"
              color="primary"
            />
          }
          label="Cafeteria is Open"
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save Settings
          </Button>
        </Box>
      </form>
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
    </Container>
  );
}

export default CafeteriaSettings;