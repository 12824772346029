import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, CircularProgress, Container } from '@mui/material';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import OrderManagement from './components/OrderManagement';
import CafeteriaLayout from './components/CafeteriaLayout';
import CafeteriaProfile from './components/CafeteriaProfile';
import CafeteriaSettings from './components/CafeteriaSettings';
import NotFound from './components/NotFound';
import Menu from './components/Menu';
import { AuthProvider, useAuth } from './contexts/AuthContext';

const theme = createTheme();

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <CircularProgress />;
  }
  
  return user ? children : <Navigate to="/login" />;
}

function PublicRoute({ children }) {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <CircularProgress />;
  }
  
  return !user ? children : <Navigate to="/dashboard" />;
}

function App() {
  const { loading } = useAuth();

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Landing Page - accessible to all */}
          <Route path="/" element={<LandingPage />} />

          {/* Public routes for login and register */}
          <Route path="/login" element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
          <Route path="/register" element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          } />

          {/* Private routes */}
          <Route path="/" element={
            <PrivateRoute>
              <CafeteriaLayout />
            </PrivateRoute>
          }>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="orders" element={<OrderManagement />} />
            <Route path="profile" element={<CafeteriaProfile />} />
            <Route path="settings" element={<CafeteriaSettings />} />
            <Route path="menus" element={<Menu />} />
          </Route>

          {/* Redirect root to dashboard for authenticated users */}
          <Route path="/" element={
            <PrivateRoute>
              <Navigate to="/dashboard" replace />
            </PrivateRoute>
          } />

          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithAuth;